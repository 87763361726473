import {GalleryImageId} from '@px/psf-themes-core';
import {Expose} from 'class-transformer';

export interface IImageCollection {
  picId: number;
  name: string;
  originalFileName: string;
}

export class ImageCollectionModel implements IImageCollection {
  picId!: number;
  originalFileName!: string;

  @Expose({name: 'id'})
  idInternal?: string;

  get name(): string {
    return this.originalFileName;
  }

  getId(): GalleryImageId {
    return this.idInternal || this.picId;
  }
}
