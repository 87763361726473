import {ImageQuality} from '@px/shared/api';
import {PlatformEnvironment} from '@px/shared/env';
import {IPSFThemesPlatformConfig, PSFThemesFeatureConfig, PSFThemesPlatformFeatures} from './platform-config';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export class PSFThemesPlatformEnvironment
  extends PlatformEnvironment<PSFThemesPlatformFeatures, PSFThemesFeatureConfig>
  implements IPSFThemesPlatformConfig
{
  protected static self: PSFThemesPlatformEnvironment;

  readonly apiMockServerURL: string | null = null;
  readonly debugEnableFragments = false;
  readonly debugForceGalleryURL: string = '';
  readonly debugHideHeroLoader = false;
  readonly debugImageSize!: number;
  readonly debugShowGitCommits: boolean = false;
  readonly debugUseMockServer: boolean = false;

  readonly FACEBOOK_APP_ID!: string;

  readonly MAX_SEND_TO_PHOTOGRAPHER_MESSAGE_LENGTH = 500;

  readonly INTERMEDIATE_IMAGE_RES = {
    [ImageQuality.FHD]: [1920, 1080],
    [ImageQuality.UHD]: [3840, 2160],
  };

  readonly MAX_IMAGE_DPR = 4.0;
  readonly PREVIEW_IMAGE_DOWNSCALE_FACTOR = 0.5;

  readonly PRELOAD_IMAGES_COUNT_INITIAL = 16;
  readonly PRELOAD_IMAGES_COUNT_SCROLL = 20;

  readonly WINDOW_EVENT_SCROLL_THROTTLE_TIME = 100;
  readonly WINDOW_EVENT_RESIZE_THROTTLE_TIME = 100;

  readonly SNACKBAR_DURATION_TIME = 2000;

  readonly SIV = {
    animationDuration: 333,
    preloadOrder: [[0], [1, 2, 3], [-1, -2]],
    preloaderGroupName: 'SIV',
  };

  readonly DOWNLOAD_OPTIONS = {
    [ImageQuality.ORIGINALS]: {
      title: marker('DOWNLOAD_DIALOG_SIZE_ORIGINAL'),
      order: 1,
    },
    [ImageQuality.FHD]: {
      title: marker('DOWNLOAD_DIALOG_SIZE_WEB'),
      order: 2,
    },
  };

  readonly PREVIEW_URL_FRAGMENT = 'previewMode';
  readonly GALLERY_HOME_PARAM = 'all';

  override readonly LATEST_BROWSERS_VER = {
    chrome: '>=111.0',
    firefox: '>=111.0',
    safari: '>=15.0',
    edge: '>=111',
    iOSFacebook: '>=15.0',
    electron: '>14',
  };

  get COPY_FILE_URL(): string {
    return this.PSF_HOST.concat('/copy/file-list');
  }

  get getPeopleAvatarQualityThreshold(): number | undefined {
    const {peopleAvatarQualityThreshold} =
      (this.getFeatureConfig(PSFThemesPlatformFeatures.PEOPLE_NAVIGATION) as {
        peopleAvatarQualityThreshold: number;
      }) ?? {};

    return peopleAvatarQualityThreshold;
  }

  constructor() {
    super();
    PSFThemesPlatformEnvironment.self = this;
  }

  static getSelf(): PSFThemesPlatformEnvironment {
    return this.self;
  }

  hasConnectionIssueFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.CONNECTION_ISSUE);
  }

  hasBrowserSupportFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.UNSUPPORTED_BROWSER_OVERRIDE);
  }

  hasCheckUpdatesFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.CHECK_UPDATES);
  }

  hasPlayFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.PLAY);
  }

  hasP1IntegrationFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.P1_INTEGRATION);
  }

  hasPeopleNavigationFeature(): boolean {
    return this.hasFeature(PSFThemesPlatformFeatures.PEOPLE_NAVIGATION);
  }

  hasPeopleInSectionFeature(): boolean {
    const {peopleInSection} = (this.getFeatureConfig(PSFThemesPlatformFeatures.PEOPLE_NAVIGATION) as {
      peopleAtBottom: boolean;
      peopleInSection: boolean;
    }) ?? {
      peopleInSection: false,
    };
    return peopleInSection;
  }
}
