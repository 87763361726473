@if (isVisible) {
  <div
    class="banner"
    [class.banner--warning]="type === 'warning'"
    [class.banner--info]="type === 'info'"
    [class.banner--primary]="type === 'primary'"
    [class.banner--transparent]="type === 'transparent'"
    [class.banner--center]="isCentered"
    @puiSlideHeightAnim
  >
    <div class="banner__inner">
      <div class="banner__message message">
        <ng-content select="message" />
        <div class="message__prefix">
          <ng-content select="prefix" />
        </div>
        <div class="message__text">
          <ng-content select="text" />
        </div>
        <div class="message__actions">
          <ng-content select="actions" />
        </div>
      </div>
      <div class="banner__actions">
        <ng-content select="action" />
        <div class="banner__actions banner__actions--close">
          <ng-content select="close" />
        </div>
      </div>
    </div>
  </div>
}
