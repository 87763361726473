import {booleanAttribute, Directive, input, output} from '@angular/core';

export type PuiButtonType = 'button' | 'submit' | 'reset';

@Directive()
export class PuiButtonCore {
  click$ = output<Event>();

  buttonType = input<PuiButtonType>('button');
  tabIndex = input(0);
  disabled = input(false, {transform: booleanAttribute});
  loading = input(false, {transform: booleanAttribute});
  fullWidth = input(false, {transform: booleanAttribute});

  click(event: Event): void {
    if (this.disabled()) return;
    this.click$.emit(event);
  }
}
