import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {PuiButtonCore, PuiButtonCoreComponent} from '@pui/components/button';

export type GdsButtonVariant = 'solid' | 'outline' | 'naked' | 'square' | 'input' | 'circle';
export type GdsButtonSize = 'xsmall' | 'small' | 'medium' | 'large';

@Component({
  selector: 'gds-button',
  imports: [PuiButtonCoreComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdsButtonComponent extends PuiButtonCore {
  size = input<GdsButtonSize>('medium');
  variant = input<GdsButtonVariant>('solid');
}
