import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {PxFile} from '../models/px-file';
import {FileFactoryService} from '../services/file-factory.service';

type ProcessEntryParams = FileSystemDirectoryEntry | FileSystemFileEntry;
type ProcessItemsParams = DataTransferItem & {getAsEntry(): FileSystemEntry | null};

@Directive({
  selector: '[pxFileDropSelector]',
  standalone: false,
})
export class FileDropSelectorDirective {
  private isDragOver = false;

  @Output() filesDropped$: EventEmitter<PxFile[]> = new EventEmitter<PxFile[]>();
  @Output() dragOver$: EventEmitter<void> = new EventEmitter();
  @Output() dragLeave$: EventEmitter<void> = new EventEmitter();

  constructor(
    private pxFileFactory: FileFactoryService,
    private elementRef?: ElementRef
  ) {}

  private processEntry = async (entry: ProcessEntryParams, path = ''): Promise<PxFile[] | null> => {
    if (entry.isFile && 'file' in entry) {
      const file = await new Promise<File>((resolve, reject) => entry.file(resolve, reject));
      return [this.pxFileFactory.create(file, `${path}${entry.name}`)];
    } else if (entry.isDirectory && 'createReader' in entry) {
      const reader = entry.createReader();
      let entries: FileSystemEntry[] = [];
      let readEntries: FileSystemEntry[] = [];

      do {
        readEntries = await new Promise<FileSystemEntry[]>((resolve, reject) => reader.readEntries(resolve, reject));
        entries = [...entries, ...readEntries];
      } while (readEntries.length !== 0);

      const nestedFiles: PxFile[][] = (await Promise.all(
        entries.map(nestedEntry => this.processEntry(nestedEntry as ProcessEntryParams, `${path}${entry.name}/`))
      )) as PxFile[][];

      return nestedFiles.flat().filter(file => file !== null);
    }

    return null;
  };

  private processItems = async (item: ProcessItemsParams): Promise<PxFile[] | null> => {
    const entry = item.webkitGetAsEntry() || item.getAsEntry();

    if (entry) {
      return this.processEntry(entry as ProcessEntryParams);
    }

    return null;
  };

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDragOver) {
      this.isDragOver = true;
      this.dragOver$.next();
    }
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = this.elementRef?.nativeElement;
    const rect = dropZone?.getBoundingClientRect();
    const x = event.clientX;
    const y = event.clientY;

    if (rect && (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom)) {
      this.dragLeave$.next();
      this.isDragOver = false;
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!event.dataTransfer) {
      return;
    }

    const items: DataTransferItemList = event.dataTransfer.items;

    const filesPromises: Promise<PxFile[] | null>[] = Array.from(items).map((item: DataTransferItem) =>
      this.processItems(item as ProcessItemsParams)
    );

    Promise.all(filesPromises).then((filesArrays: Awaited<PxFile[] | null>[]): void => {
      const files: PxFile[] = filesArrays.flat().filter((file: PxFile | null): file is PxFile => file !== null);

      this.filesDropped$.emit(files);
    });
  }
}
