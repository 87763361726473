<div class="unsupported-browser">
  <h1>{{ 'UNSUPPORTED_BROWSER_TITLE' | translate }}</h1>

  <h2>{{ 'UNSUPPORTED_BROWSER_DESCRIPTION' | translate }}</h2>

  <div class="browsers-links-container">
    <a class="browser-link" href="https://www.google.com/chrome/browser/desktop/" target="_blank">
      <mds-icon icon="pxChrome" />
      {{ 'GOOGLE_CHROME' | translate }}
    </a>
    <a class="browser-link" href="https://www.microsoft.com/en-us/edge/" target="_blank">
      <mds-icon icon="pxEdge" />
      {{ 'MICROSOFT_EDGE' | translate }}
    </a>
    <a class="browser-link" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
      <mds-icon icon="pxFirefox" />
      {{ 'MOZILLA_FIREFOX' | translate }}
    </a>
    <a class="browser-link" href="https://support.apple.com/downloads/safari/" target="_blank">
      <mds-icon icon="pxSafari" />
      {{ 'APPLE_SAFARI' | translate }}
    </a>
  </div>

  @if (isBrowserOverrideEnabled) {
    <mds-button
      class="unsupported-browser__action"
      iconPosition="left"
      color="neutral"
      (click$)="overrideGuardSettings()"
    >
      <mds-icon icon="caWarningColor" />
      {{ 'Continue with unsupported browser' | translate }}
    </mds-button>
  }
</div>
