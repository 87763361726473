import {booleanAttribute, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {puiSlideHeightAnim} from '@pui/cdk/animations';
import {MdsBannerType} from './banner.types';

@Component({
  selector: 'mds-banner',
  imports: [],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [puiSlideHeightAnim],
})
export class MdsBannerComponent {
  @Input() type: MdsBannerType | undefined;
  @Input({transform: booleanAttribute}) isVisible = true;
  @Input({transform: booleanAttribute}) isCentered = false;
}
