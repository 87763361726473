import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {PSFThemesPlatformEnvironment, PSFThemesPlatformFeatures} from '@px/psf-themes/platform-env';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';

@Component({
  template: '<router-outlet />',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent {
  private readonly translate = inject(TranslateService);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly platform = inject(PSFThemesPlatformEnvironment);
  private readonly window = inject(WINDOW_TOKEN);
  private readonly useGqlApi = !!this.window.location.search.includes('useGqlApi');

  constructor() {
    if (this.platform.GTM_ENABLED) {
      this.googleAnalyticsService.init(this.platform.GTM_ID);
    }

    this.translate.use(this.translate.getBrowserLang());

    if (this.useGqlApi) {
      this.platform.FEATURES[PSFThemesPlatformFeatures.PROXY_GQL_CA] = this.useGqlApi;
    }
  }
}
