<button
  class="button"
  [class.button--disabled]="disabled()"
  [class.button--full-width]="fullWidth()"
  [disabled]="disabled()"
  [type]="buttonType()"
  [tabIndex]="tabIndex()"
  (click)="click$.emit($event)"
  (keydown.enter)="click$.emit($event)"
>
  <ng-content />
</button>
