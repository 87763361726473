import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {PlatformEnvironment} from '../platform-environment';
import {IGuardData} from '../interfaces/guard-data.interface';

@Injectable()
export class FeatureFlagGuard {
  private readonly platform = inject(PlatformEnvironment);
  private readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const data = route.data as IGuardData;
    const hasAccess = data.features?.every(item => this.platform.hasFeature(item));

    if (!hasAccess) {
      this.router.navigateByUrl('/404'); //TODO: token for basic routes
    }

    return hasAccess;
  }
}
