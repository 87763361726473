import {ApolloClientOptions, InMemoryCache, NormalizedCacheObject, Operation} from '@apollo/client';
import {BatchOptions} from 'apollo-angular/http/types';
import {inject} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import {GQL_CACHE_TOKEN} from '../tokens/cache.token';
// eslint-disable-next-line no-restricted-imports
import {getMainDefinition} from '@apollo/client/utilities';
import {Kind, OperationTypeNode} from 'graphql/language';
import {PHOTOGRAPHER_BACKEND_CLIENT_ID} from '@px/photographer-auth/domain';
// eslint-disable-next-line no-restricted-imports
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {CloseCode, createClient} from 'graphql-ws';
import {filter, firstValueFrom, Subscription, timer} from 'rxjs';
import {SessionProviderFacade} from '@px/shared/session-provider';

export abstract class Resolver {
  protected readonly sessionProviderFacade = inject(SessionProviderFacade);
  protected readonly platform = inject(PlatformEnvironment);
  protected readonly clientId = inject(PHOTOGRAPHER_BACKEND_CLIENT_ID);
  protected readonly cache = inject(GQL_CACHE_TOKEN, {optional: true}) ?? new InMemoryCache();

  protected tokenExpirySubscription: Subscription | null = null;

  protected clientOptionsBase: Partial<ApolloClientOptions<NormalizedCacheObject>> = {
    name: this.clientId,
    version: this.platform.APOLLO_STUDIO_VERSION,
  };

  protected httpLinkOptionsBase: BatchOptions = {
    withCredentials: false,
    batchMax: 30,
    batchInterval: 20,
  };

  protected batchOperationTest = (operation: Operation): boolean => {
    const {canBeBatched} = operation.getContext();

    return canBeBatched || canBeBatched === undefined;
  };

  protected subscriptionOperationTest = ({query}: Operation): boolean => {
    const definition = getMainDefinition(query);
    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  };

  protected getWSLinkOptions(): GraphQLWsLink {
    return new GraphQLWsLink(
      createClient({
        lazy: true,
        url: this.platform.GRAPH_QL_ENDPOINT_WS,

        connectionParams: async () => {
          const session = this.sessionProviderFacade.getSessionService();

          if (session?.isSessionExpired()) {
            await firstValueFrom(session?.session$.pipe(filter(() => !session?.isSessionExpired())));
          }

          return {Authorization: `Bearer ${session?.getSessionToken()}`};
        },
        on: {
          connected: socket => {
            const session = this.sessionProviderFacade.getSessionService();

            this.tokenExpirySubscription?.unsubscribe();

            const currentTokenExpiresIn = session?.getCurrentTokenExpiresIn() ?? 0;
            this.tokenExpirySubscription = timer(currentTokenExpiresIn).subscribe(() => {
              if ((socket as WebSocket).readyState === WebSocket.OPEN) {
                (socket as WebSocket).close(CloseCode.Forbidden, 'Forbidden');
              }
            });
          },
          error: error => {
            console.warn(error);
          },
        },
      })
    );
  }
}
