<div class="c-snackbar__inner">
  @if (icon) {
    <mds-icon class="c-snackbar__icon" [icon]="icon" />
  }
  <div class="c-snackbar__body">
    {{ text }}
  </div>
  <div class="c-snackbar__actions c-snackbar__actions--buttons">
    <ng-container [ngTemplateOutlet]="buttonTemplateRef" [ngTemplateOutletContext]="{call, $implicit: action}" />
  </div>
</div>
