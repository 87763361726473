import {inject, Injectable} from '@angular/core';

import {Apollo, gql} from 'apollo-angular';
import {filter, map, Observable} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {IFavoriteImageInput} from '../entities/inputs/favorite-image.input';
import {FavoriteImageModel, IFavoriteImageResponse} from '../entities/responses/favorite-image.model';
import {IVisitorSubmissionResponse, VisitorSubmissionModel} from '../entities/responses/visitor-submition.model';
import {IGalleryContextInput} from '../entities/inputs/gallery-context.input';
import {IVisitorSubmissionsResponse, VisitorSubmissionsModel} from '../entities/responses/visitor-submissions.model';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class FavoritesDataService {
  private readonly apollo = inject(Apollo);
  private readonly platform = inject(PlatformEnvironment);

  private readonly imageFragment = gql`
    fragment Image on Image {
      id
      picId
      originalFileName
    }
  `;

  private readonly imageFragmentOld = gql`
    fragment ImageOld on Image {
      picId
      originalFileName
    }
  `;

  private readonly updateImageFavoriteFlagMutation = gql<
    {updateImageFavoriteFlag: IFavoriteImageResponse},
    {input: IFavoriteImageInput}
  >`
    mutation UpdateImageFavoriteFlag($input: UpdateImageFavoriteFlagInput!) {
      updateImageFavoriteFlag(input: $input) {
        image {
          ...Image
        }
        state
      }
    }
    ${this.imageFragment}
  `;

  private readonly submitFavoritesSetMutation = gql<
    boolean,
    {galleryContext: IGalleryContextInput; imageCollectionId: string; productFamily: ProductFamily}
  >`
    mutation SubmitFavoritesSet(
      $galleryContext: GalleryContext!
      $imageCollectionId: String!
      $productFamily: ProductFamily!
    ) {
      submitFavoritesSet(
        galleryContext: $galleryContext
        imageCollectionId: $imageCollectionId
        productFamily: $productFamily
      )
    }
  `;

  private collectionOwnSubmissionsQuery = gql<
    {collectionOwnSubmissions: IVisitorSubmissionResponse[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnSubmissions($imageCollectionId: String!) {
      collectionOwnSubmissions(imageCollectionId: $imageCollectionId) {
        dateCreated
        images {
          ...Image
        }
      }
    }
    ${this.imageFragment}
  `;

  private collectionOwnSubmissionsQueryOld = gql<
    {collectionOwnSubmissions: IVisitorSubmissionResponse[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnSubmissions($imageCollectionId: String!) {
      collectionOwnSubmissions(imageCollectionId: $imageCollectionId) {
        dateCreated
        images {
          ...ImageOld
        }
      }
    }
    ${this.imageFragmentOld}
  `;

  private collectionOwnFavoritesQuery = gql<
    {collectionOwnFavorites: IFavoriteImageResponse[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnFavorites($imageCollectionId: String!) {
      collectionOwnFavorites(imageCollectionId: $imageCollectionId) {
        image {
          ...Image
        }
        state
      }
    }
    ${this.imageFragment}
  `;

  private collectionOwnFavoritesQueryOld = gql<
    {collectionOwnFavorites: IFavoriteImageResponse[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnFavorites($imageCollectionId: String!) {
      collectionOwnFavorites(imageCollectionId: $imageCollectionId) {
        image {
          ...ImageOld
        }
        state
      }
    }
    ${this.imageFragmentOld}
  `;

  private visitorsSubmissions = gql<
    {visitorsCollectionActivity: IVisitorSubmissionsResponse[]},
    {imageCollectionId: string; productFamily: ProductFamily}
  >`
    query VisitorsCollectionActivity($imageCollectionId: String!, $productFamily: ProductFamily!) {
      visitorsCollectionActivity(imageCollectionId: $imageCollectionId, productFamily: $productFamily) {
        submissions {
          dateCreated

          images {
            ...Image
          }
        }

        visitor {
          id
          status
          email
          roles
        }

        favorites {
          image {
            ...Image
          }

          dateUpdated
          dateCreated
          state
        }
      }
    }
    ${this.imageFragment}
  `;

  private visitorsSubmissionsOld = gql<
    {visitorsCollectionActivity: IVisitorSubmissionsResponse[]},
    {imageCollectionId: string; productFamily: ProductFamily}
  >`
    query VisitorsCollectionActivity($imageCollectionId: String!, $productFamily: ProductFamily!) {
      visitorsCollectionActivity(imageCollectionId: $imageCollectionId, productFamily: $productFamily) {
        submissions {
          dateCreated

          images {
            ...ImageOld
          }
        }

        visitor {
          id
          status
          email
          roles
        }

        favorites {
          image {
            ...ImageOld
          }

          dateUpdated
          dateCreated
          state
        }
      }
    }
    ${this.imageFragmentOld}
  `;

  get hasGQL(): boolean {
    return this.platform.hasFeature('PROXY_GQL');
  }

  get hasGqlCa(): boolean {
    return this.platform.hasFeature('PROXY_GQL_CA');
  }

  private getVisitorsSubmissionsWatcher(
    imageCollectionId: string,
    interval: number
  ): Observable<VisitorSubmissionsModel[]> {
    return this.apollo
      .watchQuery({
        query: this.hasGQL ? this.visitorsSubmissions : this.visitorsSubmissionsOld,
        variables: {imageCollectionId, productFamily: ProductFamily.PSF},
        pollInterval: interval,
        errorPolicy: 'all',
      })
      .valueChanges.pipe(
        filter(result => !!result),
        map(result => plainToInstance(VisitorSubmissionsModel, result.data?.visitorsCollectionActivity))
      );
  }

  collectionOwnFavorites(imageCollectionId: string): Observable<FavoriteImageModel[]> {
    return this.apollo
      .query({
        query: this.hasGqlCa ? this.collectionOwnFavoritesQuery : this.collectionOwnFavoritesQueryOld,
        variables: {imageCollectionId},
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(FavoriteImageModel, data?.collectionOwnFavorites))
      );
  }

  submitFavoritesSet(
    galleryContext: IGalleryContextInput,
    imageCollectionId: string,
    productFamily: ProductFamily
  ): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.submitFavoritesSetMutation,
        variables: {
          galleryContext,
          imageCollectionId,
          productFamily,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => !!data)
      );
  }

  updateImageFavoriteFlag(input: IFavoriteImageInput): Observable<FavoriteImageModel> {
    return this.apollo
      .mutate({
        mutation: this.updateImageFavoriteFlagMutation,
        variables: {
          input,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(FavoriteImageModel, data?.updateImageFavoriteFlag))
      );
  }

  collectionOwnSubmissions(imageCollectionId: string): Observable<VisitorSubmissionModel[]> {
    return this.apollo
      .query({
        query: this.hasGqlCa ? this.collectionOwnSubmissionsQuery : this.collectionOwnSubmissionsQueryOld,
        variables: {
          imageCollectionId,
        },
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(VisitorSubmissionModel, result.data?.collectionOwnSubmissions))
      );
  }

  getVisitorsSubmissions(imageCollectionId: string, interval?: number): Observable<VisitorSubmissionsModel[]> {
    if (interval) {
      return this.getVisitorsSubmissionsWatcher(imageCollectionId, interval);
    }

    return this.apollo
      .query({
        query: this.hasGQL ? this.visitorsSubmissions : this.visitorsSubmissionsOld,
        variables: {imageCollectionId, productFamily: ProductFamily.PSF},
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result),
        map(result => plainToInstance(VisitorSubmissionsModel, result.data?.visitorsCollectionActivity))
      );
  }

  dropFavoritesCache(): void {
    this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'visitorsCollectionActivity'});
    this.apollo.client.cache.gc();
  }
}
