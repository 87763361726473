import {inject, Injectable} from '@angular/core';
import {HttpLink} from 'apollo-angular/http';
import {ApolloClientOptions, NormalizedCacheObject, split} from '@apollo/client';
import {Resolver} from '../entities/resolver';

@Injectable()
export class ApolloOptionsResolverService extends Resolver {
  protected readonly httpLink = inject(HttpLink);

  resolve(): ApolloClientOptions<NormalizedCacheObject> {
    const httpLinkHandler = this.httpLink.create({...this.httpLinkOptionsBase, uri: this.platform.GRAPH_QL_ENDPOINT});

    const graphQLWsLink = this.getWSLinkOptions();
    const link = split(this.subscriptionOperationTest, graphQLWsLink, httpLinkHandler);

    return {
      ...this.clientOptionsBase,
      cache: this.cache,
      link,
    };
  }
}
