<mds-banner class="update-notification-banner" [isVisible]="isShown" isCentered>
  <ng-container ngProjectAs="message">
    {{ message() }}
  </ng-container>
  <ng-container ngProjectAs="action">
    <div class="update-notification-banner__refresh">
      <mds-button type="outlined" (click$)="refresh()">
        {{ 'Update' | translate }}
      </mds-button>
    </div>
  </ng-container>
</mds-banner>
