import {inject, Injectable} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {PlatformEnvironment} from '@px/shared/env';
import {instanceToPlain, plainToClass} from 'class-transformer';
import dayjs from 'dayjs';
import {head} from 'ramda';
import {BehaviorSubject, catchError, combineLatest, map, Observable, of, shareReplay, tap} from 'rxjs';
import {FAVORITE_REMINDER_FEATURE_NAME} from '../consts/favorite-reminder-timeout-feature-name.const';
import {IGalleryContextInput} from '../entities/inputs/gallery-context.input';
import {GalleryInfoFavorites} from '../entities/interfaces/gallery-info-favorites.interface';
import {GalleryImageId} from '../entities/interfaces/image-id.interface';
import {FavoriteImageModel} from '../entities/responses/favorite-image.model';
import {VisitorSubmissionModel} from '../entities/responses/visitor-submition.model';
import {FavoritesDataService} from '../infrastructure/favorites-data.service';

@Injectable()
export class VisitorFavoritesFacadeService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly product: ProductFamily = inject(PRODUCT_FAMILY);
  private readonly favoritesDataService = inject(FavoritesDataService);

  private favoritesInternal$ = new BehaviorSubject<Record<GalleryImageId, boolean>>({});
  private lastSubmissionInternal$ = new BehaviorSubject<VisitorSubmissionModel | null>(null);
  private collection: FavoriteImageModel[] = [];
  private galleryInfo!: GalleryInfoFavorites;

  favorites$: Observable<Record<GalleryImageId, boolean>> = this.favoritesInternal$.pipe(
    map(favorites => {
      const result: Record<GalleryImageId, boolean> = {};

      for (const photo of this.galleryInfo?.photos ?? []) {
        const id = photo.getId();
        result[id] = favorites[id];
      }

      return result;
    })
  );

  lastSubmission$ = this.lastSubmissionInternal$.pipe(
    map(lastSubmission => {
      if (lastSubmission) {
        const photoIds = this.galleryInfo.photos.map(item => item.getId());
        lastSubmission.images = lastSubmission.images?.filter(item => photoIds.includes(item.getId())) ?? [];

        return lastSubmission;
      }

      return null;
    })
  );

  favoritesCount$: Observable<number | null> = this.favorites$.pipe(
    map(favorites => Object.values(favorites ?? {}).filter(status => status).length),
    shareReplay(1)
  );

  isFavoritesSubmitted$: Observable<boolean | null> = combineLatest([this.favorites$, this.lastSubmission$]).pipe(
    map(([favorites, lastSubmission]) => {
      let isFavoritesSubmitted: boolean;
      try {
        const lastSubmittedFavorites = lastSubmission?.favoritesSnapshot;
        const currentFavoritesArr = Object.entries(favorites ?? {})
          .filter(([, status]) => status)
          .map(([id]) => (this.hasGQL ? id : Number(id)));

        const compareFn = (a: GalleryImageId, b: GalleryImageId): number => a.toString().localeCompare(b.toString());
        isFavoritesSubmitted = lastSubmittedFavorites?.length
          ? JSON.stringify(lastSubmittedFavorites.sort(compareFn)) ===
            JSON.stringify(currentFavoritesArr.sort(compareFn))
          : false;
      } catch (e) {
        isFavoritesSubmitted = false;
      }

      return isFavoritesSubmitted;
    }),
    shareReplay(1)
  );

  lastSubmissionDate$: Observable<Date | null> = this.lastSubmissionInternal$.pipe(
    map(lastSubmission => (lastSubmission ? lastSubmission.dateCreated.toDate() : null)),
    shareReplay(1)
  );

  lastSubmissionDate = toSignal(this.lastSubmissionDate$);

  get favorites(): Record<GalleryImageId, boolean> | null {
    return this.favoritesInternal$.value;
  }

  get hasGQL(): boolean {
    return this.platform.hasFeature('PROXY_GQL');
  }

  updateImageFavoriteState(
    imageCollectionId: string,
    imageId: GalleryImageId,
    state: boolean,
    favoritesUrl: string
  ): Observable<void> {
    const {TIMEOUT} = this.platform.FEATURE_CONFIG?.[FAVORITE_REMINDER_FEATURE_NAME] ?? {};

    const favorites = Object.assign({}, this.favoritesInternal$.value, {[imageId]: state});
    this.favoritesInternal$.next(favorites);

    const id = this.galleryInfo.photos.find(item => item.getId() === imageId)?.picId ?? imageId;

    return this.favoritesDataService
      .updateImageFavoriteFlag({
        imageCollectionId,
        imageId: id as number,
        state,
        favoritesActivityUrl: favoritesUrl,
        clientEmailTimeoutOverride: TIMEOUT || undefined,
        productFamily: this.product,
      })
      .pipe(
        tap((response: FavoriteImageModel) => {
          this.collection = [
            ...this.collection.filter(item => item.image.getId() !== response.image.getId()),
            response,
          ];
        }),
        map(() => undefined)
      );
  }

  updateFavorites(galleryInfo: GalleryInfoFavorites): Observable<void> {
    this.galleryInfo = galleryInfo;
    const favorites: Record<GalleryImageId, boolean> = {};

    return this.favoritesDataService.collectionOwnFavorites(galleryInfo.imageCollectionId).pipe(
      //TODO handle error
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      tap(collection => {
        this.collection = collection;

        for (const i of collection) {
          if (!i.image) continue;
          favorites[i.image.getId()] = i.state;
        }

        this.favoritesInternal$.next(favorites);
      }),
      map(() => undefined)
    );
  }

  clearFavorites(): void {
    this.favoritesInternal$.next({});
  }

  submitFavoritesSet(galleryContext: IGalleryContextInput, imageCollectionId: string): Observable<void> {
    try {
      const dateCreated = dayjs().unix();
      const favoritesSnapshot = Object.entries(this.favorites ?? {})
        .filter(([, state]) => state)
        .map(([id]) => (this.hasGQL ? id : Number(id)));

      this.lastSubmissionInternal$.next(
        plainToClass(VisitorSubmissionModel, {
          dateCreated,
          images: instanceToPlain(
            this.galleryInfo.photos.filter(photo => favoritesSnapshot.includes(<string>photo.getId()))
          ),
        })
      );
    } catch (e) {
      console.error(e);
    }

    return this.favoritesDataService
      .submitFavoritesSet(galleryContext, imageCollectionId, this.product)
      .pipe(map(() => undefined));
  }

  updateLastSubmittedCollection(imageCollectionId: string): Observable<void> {
    return this.favoritesDataService.collectionOwnSubmissions(imageCollectionId).pipe(
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      map(
        (submissions: VisitorSubmissionModel[]) =>
          head(submissions.sort((a, b) => (b.dateCreated.isAfter(a.dateCreated) ? 1 : -1))) ?? null
      ),
      tap(lastSubmission => this.lastSubmissionInternal$.next(lastSubmission)),
      map(() => undefined)
    );
  }

  hasFavoriteHistory(): boolean {
    return !!this.collection?.length;
  }
}
