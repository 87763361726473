<button
  [class.is-active]="active"
  [class.is-disabled]="disabled"
  [ngClass]="hostClass"
  class="c-button"
  type="button"
  [disabled]="disabled"
  (click)="click$.emit()"
>
  <ng-template #iconContentTpl>
    <ng-content select="mds-icon" />
  </ng-template>

  @if (hasIconLeft) {
    <span class="c-button__icon c-button__icon--left">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  }

  @if (!isIcon) {
    <span class="c-button__text">
      <ng-content />
    </span>
  } @else {
    <span class="c-button__icon">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  }

  @if (hasIconRight) {
    <span class="c-button__icon c-button__icon--right">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  }

  @if (hasProgress) {
    <div class="c-button__progress-bar"></div>
  }
</button>
