import {inject, Injectable} from '@angular/core';
import {HttpBatchLink} from 'apollo-angular/http';
import type {ApolloClientOptions, NormalizedCacheObject} from '@apollo/client';
import {split} from '@apollo/client';
import type {BatchOptions} from 'apollo-angular/http/types';
import {ApolloOptionsResolverService} from './apollo-options-resolver.service';

@Injectable()
export class ApolloOptionResolverBatchService extends ApolloOptionsResolverService {
  private readonly httpBatchLink = inject(HttpBatchLink);

  private httpLinkOptions: BatchOptions = {
    ...this.httpLinkOptionsBase,
  };

  override resolve(): ApolloClientOptions<NormalizedCacheObject> {
    const batchLinkHandler = this.httpBatchLink.create({
      ...this.httpLinkOptions,
      uri: this.platform.GRAPH_QL_ENDPOINT,
    });

    const httpLinkHandler = this.httpLink.create({
      ...this.httpLinkOptions,
      uri: this.platform.GRAPH_QL_ENDPOINT,
    });

    const graphQLWsLink = this.getWSLinkOptions();
    const baseLink = split(this.batchOperationTest, batchLinkHandler, httpLinkHandler);
    const link = split(this.subscriptionOperationTest, graphQLWsLink, baseLink);

    return {
      ...this.clientOptionsBase,
      cache: this.cache,
      link,
    };
  }
}
