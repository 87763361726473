import {animate, state, style, transition, trigger} from '@angular/animations';
import {DURATION, TRANSITION} from '../tokens';

export const puiSlideWith = trigger('puiSlideWith', [
  state('false', style({maxWidth: '0', opacity: '0', overflow: 'hidden'})),
  state('true', style({maxWidth: '{{maxWidth}}', opacity: '1', overflow: 'hidden'}), {
    params: {maxWidth: '*'},
  }),
  transition('false => true', [animate(TRANSITION)], DURATION),
  transition('true => false', [animate(TRANSITION)], DURATION),
]);
