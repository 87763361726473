import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ThemeConfig} from '@px/psf-themes-core';
import {THEME_PRESETS_TOKEN, ThemingColorsService, ThemingTypographyService} from '@px/psf-themes/feature-theming';
import {last} from 'rxjs';
import {UnsupportedBrowserGuard} from '../../core/guards/unsupported-browser.guard';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';
import {caWarningColor, mdsProvideIcons, pxChrome, pxEdge, pxFirefox, pxSafari} from '@pui/components/icon-core';

@UntilDestroy()
@Component({
  selector: 'app-unsupported-browser',
  providers: [mdsProvideIcons({pxChrome, pxEdge, pxFirefox, pxSafari, caWarningColor})],
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UnsupportedBrowserComponent {
  private readonly defaultTypographyPresetID = 'set-unsupported-browser';
  private readonly colorsService = inject(ThemingColorsService);
  private readonly typographyService = inject(ThemingTypographyService);
  private readonly themeConfig: ThemeConfig = inject(THEME_PRESETS_TOKEN);
  private readonly unsupportedBrowserGuard = inject(UnsupportedBrowserGuard);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly platform = inject(PSFThemesPlatformEnvironment);

  get isBrowserOverrideEnabled(): boolean {
    return this.platform.hasBrowserSupportFeature();
  }

  constructor() {
    this.colorsService.loadPresets(this.themeConfig.colorPresets);
    this.colorsService.activePresetId = 'colors-light';
    this.typographyService.loadPresets(this.themeConfig.typographyPresets, this.defaultTypographyPresetID);
    this.typographyService.loadActivePresetResources().pipe(last(), untilDestroyed(this)).subscribe();
  }

  overrideGuardSettings(): void {
    const {referer} = this.route.snapshot.queryParams;

    if (referer) {
      this.unsupportedBrowserGuard.setOverride();
      this.router.navigate([referer]);
    }
  }
}
