<pui-button-core
  class="button"
  [class.button--solid]="variant() === 'solid'"
  [class.button--outline]="variant() === 'outline'"
  [class.button--naked]="variant() === 'naked'"
  [class.button--square]="variant() === 'square'"
  [class.button--input]="variant() === 'input'"
  [class.button--circle]="variant() === 'circle'"
  [class.button--medium]="size() === 'medium'"
  [class.button--xsmall]="size() === 'xsmall'"
  [class.button--small]="size() === 'small'"
  [class.button--large]="size() === 'large'"
  [class.button--loading]="loading()"
  [class.button--disabled]="disabled()"
  [tabIndex]="tabIndex()"
  (click$)="click$.emit($event)"
  [disabled]="disabled()"
  [loading]="loading()"
  [buttonType]="buttonType()"
  [fullWidth]="fullWidth()"
>
  <ng-content />
</pui-button-core>
