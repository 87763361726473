import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {PxFile} from '../models/px-file';
import {FileFactoryService} from '../services/file-factory.service';

@Directive({
  selector: '[pxFileSelector]',
  standalone: false,
})
export class FileSelectorDirective {
  private readonly input: HTMLInputElement;

  @Input() pxFileSelectorDisabled = false;
  @Input() multiple = true;
  @Output() filesSelected$: EventEmitter<PxFile[]> = new EventEmitter<PxFile[]>();

  constructor(
    private pxFileFactory: FileFactoryService,
    private elementRef: ElementRef
  ) {
    this.input = document.createElement('input');
    this.input.type = 'file';
    this.input.multiple = this.multiple;
    this.input.style.display = 'none';

    this.input.onclick = (event: Event): void => event.stopPropagation();
    this.input.onchange = (event: Event): void => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        const files: PxFile[] = Array.from(target.files).map(f => this.pxFileFactory.create(f));
        this.filesSelected$.emit(files);
        this.input.value = '';
      }
    };
    this.elementRef.nativeElement.append(this.input);
  }

  @HostListener('click', ['$event']) onClick(event: Event): void {
    if (this.pxFileSelectorDisabled) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.open();
  }

  open(): void {
    this.input.click();
  }
}
