import {animate, animation, state, style, transition, trigger} from '@angular/animations';
import {DURATION, TRANSITION_IN, TRANSITION_OUT} from '../tokens';

export const FADE_IN_ANIMATION = animation(
  [style({opacity: '{{ start }}'}), animate('300ms', style({opacity: '{{ end }}'}))],
  {params: {start: 0, end: 1}}
);

export const puiFadeInAnimation = trigger('puiFadeIn', [
  state('void', style({opacity: 0})),
  state('*', style({opacity: 1})),
  transition('void => *', [animate(TRANSITION_IN)], DURATION),
]);

export const puiFadeInOutDisplay = trigger('puiFadeInOutDisplay', [
  transition(':enter', [style({opacity: 0}), animate(TRANSITION_IN, style({opacity: 1}))], DURATION),
  transition(':leave', [animate(TRANSITION_OUT, style({opacity: 0}))], DURATION),
]);

export const puiFadeInDisplay = trigger('puiFadeInDisplay', [
  transition(':enter', [style({opacity: 0}), animate(TRANSITION_IN, style({opacity: 1}))], DURATION),
]);
