import {DOCUMENT} from '@angular/common';
import {Component, ComponentFactoryResolver, inject, Injectable, Type, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, concat, filter, last, map, merge, Observable, of, shareReplay} from 'rxjs';
import * as WebFontLoader from 'webfontloader';
import {
  IInjectedComponent,
  IThemePreset,
  IThemePresetFonts,
  ThemePresetId,
  ThemePresets,
} from '../interfaces/theme-preset.interface';
// ------------------------------------------------------------------------------

// TODO: Move the component-based features into a subclass

/**
 * A base class for theming entity facades.
 */
@Injectable()
export abstract class ThemingEntityBase {
  static componentRegistry: Map<string, Type<Component>>;

  private requestedStylesheetUrls: string[] = [];
  private requestedFonts: IThemePresetFonts[] = [];
  private injectedComponents = new Map<ViewContainerRef, IInjectedComponent>();
  private allResourcesLoaded = false;

  protected readonly document = inject(DOCUMENT);

  protected presets: ThemePresets = {};

  defaultPresetID: ThemePresetId | null = null;
  activePresetId$ = new BehaviorSubject<ThemePresetId | null>(null);
  activePreset$ = this.activePresetId$.pipe(
    map(presetId => this.presets?.[presetId ?? '']),
    filter(preset => !!preset),
    shareReplay(1)
  );

  get activePresetId(): string {
    return this.activePresetId$.value ?? '';
  }

  set activePresetId(value) {
    // Allow null values, but don't allow values outside loaded preset IDs:
    if (value !== null && this.presets) {
      const presetNames = Object.keys(this.presets);
      if (presetNames.length === 0 || !presetNames.includes(value)) {
        // console.error('Preset not found:', value);
        return;
      }
    }

    this.activePresetId$.next(value);
  }

  get activePreset(): IThemePreset | null {
    return this.presets?.[this.activePresetId] ?? null;
  }

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
    this.initDefaultValues();

    if (this.defaultPresetID) {
      this.activePresetId = this.defaultPresetID;
    }
  }

  // ToDo I prefer to use component for this
  private injectStylesheet(stylesheetURL: string): Observable<string> {
    if (this.requestedStylesheetUrls.includes(stylesheetURL)) {
      return of(stylesheetURL);
    }

    this.requestedStylesheetUrls.push(stylesheetURL);

    return new Observable(subscriber => {
      const linkEl: HTMLLinkElement = this.document.createElement('link');
      linkEl.setAttribute('rel', 'stylesheet');
      linkEl.setAttribute('href', stylesheetURL);
      linkEl.addEventListener('load', () => {
        subscriber.next(stylesheetURL);
        subscriber.complete();
      });

      linkEl.addEventListener('error', () => {
        console.error('Error loading stylesheet', stylesheetURL);
        subscriber.error(new Error('Error loading ' + stylesheetURL));
        subscriber.complete();
      });

      this.document.head.appendChild(linkEl);
    });
  }

  private setInjectedComponent(container: ViewContainerRef, presetId: ThemePresetId, component: Component): void {
    this.injectedComponents.set(container, {presetId, component});
  }

  loadPresets(presets: ThemePresets, defaultPresetID: ThemePresetId | null = null): void {
    this.presets = presets;

    if (defaultPresetID) {
      this.defaultPresetID = defaultPresetID;
      this.activePresetId = this.defaultPresetID;
      return;
    }

    this.defaultPresetID = null;
    this.activePresetId = '';
  }

  getPresets(): Record<string, IThemePreset> {
    return this.presets;
  }

  getWrapperClassName(presetID: ThemePresetId): string | null {
    if (!presetID) {
      return null;
    }
    return this.presets[presetID].wrapperClassName;
  }

  getActiveWrapperClassName(): string {
    return this.getWrapperClassName(this.activePresetId) ?? '';
  }

  getComponent(presetID: ThemePresetId): Type<Component> | null {
    if (!presetID) {
      return null;
    }

    return ThemingEntityBase.componentRegistry.get(this.presets[presetID].componentName ?? '') ?? null;
  }

  getActiveComponent(): Type<unknown> | null {
    return this.getComponent(this.activePresetId);
  }

  getComponentParams(presetID: ThemePresetId): Record<string, string> | null {
    if (!presetID) {
      return null;
    }

    return this.presets[presetID].componentParams ?? null;
  }

  getActiveComponentParams(): Record<string, string> | null {
    return this.getComponentParams(this.activePresetId);
  }

  injectActiveComponent(container: ViewContainerRef): Component | undefined {
    // Will not inject the component if the previously injected component is the same as the active one:
    if (this.getInjectedComponent(container)?.presetId === this.activePresetId) {
      return this.getInjectedComponent(container)?.component;
    }

    if (container.length > 0) {
      container.clear();
    }

    const activeComponent = this.getActiveComponent();
    const factory = this.componentFactoryResolver.resolveComponentFactory(activeComponent as Type<Component>);
    const newComponent = container.createComponent(factory).instance;
    this.setInjectedComponent(container, this.activePresetId, newComponent);
    (newComponent as unknown as {params: Record<string, string> | null}).params = this.getActiveComponentParams();
    return newComponent;
  }

  getInjectedComponent(container: ViewContainerRef): IInjectedComponent | undefined {
    return this.injectedComponents.get(container);
  }

  getStylesheetURLs(presetID: ThemePresetId): string[] {
    if (this.presets[presetID].stylesheetURLs) {
      return this.presets[presetID].stylesheetURLs ?? [];
    } else {
      return [];
    }
  }

  getFonts(presetID: ThemePresetId): IThemePresetFonts {
    return this.presets[presetID].fonts ?? {families: []};
  }

  /**
   * Loads fonts using WebFontLoader. Does not support concurrency.
   */
  loadFonts(fonts: IThemePresetFonts): Observable<string> {
    if (this.requestedFonts.includes(fonts)) {
      return of(fonts.families.toString());
    }
    this.requestedFonts.push(fonts);

    return new Observable<string>(subscriber => {
      const config = {
        custom: {
          families: fonts.families,
          urls: fonts.urls,
        },
        active: (): void => {
          subscriber.next(fonts.families.toString());
          subscriber.complete();
        },
        inactive: (): void => {
          subscriber.error(new Error('Error loading ' + fonts.families.toLocaleString()));
          subscriber.complete();
          console.error('Font(s) could not be loaded', fonts);
        },
      };
      WebFontLoader.load(config);
    });
  }

  /**
   * Loads the external resources (stylesheets, fonts) for the given presetID.
   * The returned observable does not support concurrency.
   */
  loadPresetResources(presetID: ThemePresetId, shouldLoadFonts = true): Observable<unknown> {
    const ret: Observable<unknown>[] = [];
    this.getStylesheetURLs(presetID).forEach(stylesheetURL => {
      if (stylesheetURL) {
        ret.push(this.injectStylesheet(stylesheetURL));
      }
    });
    const fonts = this.getFonts(presetID);
    if (shouldLoadFonts) {
      if (fonts && fonts.families) {
        ret.push(this.loadFonts(fonts));
      }
    }

    return merge(...ret);
  }

  loadAllResources(shouldLoadFonts = true): Observable<boolean> {
    if (this.allResourcesLoaded) {
      return of(true);
    }

    const obs: Observable<unknown>[] = [];
    for (const presetID in this.presets) {
      obs.push(this.loadPresetResources(presetID, shouldLoadFonts));
    }
    // Using concat -- loadPresetResources does not support concurrency:
    // TODO: Error handling
    return concat(...obs).pipe(
      last(),
      map(() => {
        this.allResourcesLoaded = true;
        return true;
      })
    );
  }

  loadActivePresetResources(): Observable<unknown> {
    return this.loadPresetResources(this.activePresetId);
  }

  protected abstract initDefaultValues(): void;
}
