import {Injectable, inject, provideAppInitializer} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';
import type {datadogRum} from '@datadog/browser-rum';
import type {User} from '@datadog/browser-core';
import type {RumEvent} from '@datadog/browser-rum-core';

@Injectable()
export class DataDogService {
  private datadogRum?: typeof datadogRum;

  constructor(private readonly platform: PlatformEnvironment) {}

  async setup(): Promise<void> {
    if (!this.platform.DATA_DOG_ENABLED) {
      return;
    }

    try {
      const {datadogRum} = await import('@datadog/browser-rum');
      this.datadogRum = datadogRum;

      this.datadogRum.init({
        site: 'datadoghq.com',
        applicationId: this.platform.DATA_DOG_APP_ID,
        clientToken: this.platform.DATA_DOG_CLIENT_TOKEN,
        service: this.platform.DATA_DOG_SERVICE,
        env: this.platform.DEPLOY_ENV,
        version: this.platform.APP_VERSION,
        sessionSampleRate: this.platform.DATA_DOG_SESSION_SAMPLE_RATE,
        sessionReplaySampleRate: this.platform.DATA_DOG_SESSION_REPLAY_SAMPLE_RATE,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: (event: RumEvent): void => {
          event.context = {
            ...event.context,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(performance['memory'] ?? {}),
          };
        },
      });

      this.datadogRum.startSessionReplayRecording();
    } catch (e) {
      console.error(e);
    }
  }

  setUser(user: User): void {
    this.datadogRum?.setUser(user);
  }

  clearUser(): void {
    this.datadogRum?.clearUser();
  }
}

export const DATA_DOG_SETUP_INITIALIZER = provideAppInitializer(() => {
  const initializerFn = (
    (dataDogService: DataDogService): (() => Promise<void>) =>
    () =>
      dataDogService.setup()
  )(inject(DataDogService));
  return initializerFn();
});
