import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {THEME_PRESETS_TOKEN, ThemingColorsService, ThemingTypographyService} from '@px/psf-themes/feature-theming';
import {last} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PageNotFoundComponent implements OnInit {
  private readonly colorsService = inject(ThemingColorsService);
  private readonly typographyService = inject(ThemingTypographyService);
  private readonly translateService = inject(TranslateService);
  private readonly titleService = inject(Title);
  private readonly themeConfig = inject(THEME_PRESETS_TOKEN);

  private defaultTypographyPresetID = 'set-404';

  constructor() {
    this.initThemingServices();
  }

  private initThemingServices(): void {
    this.colorsService.loadPresets(this.themeConfig.colorPresets);
    this.colorsService.activePresetId = 'colors-light';

    this.typographyService.loadPresets(this.themeConfig.typographyPresets, this.defaultTypographyPresetID);
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.instant('PAGE_NOT_FOUND_TITLE'));
    this.typographyService.loadActivePresetResources().pipe(last(), untilDestroyed(this)).subscribe();
  }
}
