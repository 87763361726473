export interface IPICImageLinkData {
  userId: number | null;
  active_storage_service: string;
  image_path: string;
  fullLink: string | null;
}

export class ImageLinkParser {
  static parseImageLinkToPicImageLinkData(fullLink?: string): IPICImageLinkData {
    if (!fullLink) {
      return {
        userId: null,
        active_storage_service: 'aws',
        image_path: '',
        fullLink: null,
      };
    }

    const regex = /https:\/\/[^/]+\/([^/]+)\/[^/]+\/[^/]+\/([^/]+)\/([^?]+)/;

    const match = fullLink.match(regex);

    if (!match) {
      throw new Error('Invalid link format');
    }

    const active_storage_service = match[1];
    const userId = match[2];
    const image_path = match[3];

    return {
      userId: Number.parseInt(userId, 10),
      active_storage_service,
      image_path,
      fullLink,
    };
  }
}
