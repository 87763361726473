import {ErrorHandler, inject, Injectable, NgZone} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {
  combineLatest,
  filter,
  first,
  fromEvent,
  interval,
  map,
  merge,
  Observable,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import dayjs from 'dayjs';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {SENTRY_TOKEN} from '@px/shared/data-access/sentry';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

type IUpdateSeverity = 'critical' | 'major' | 'minor' | 'patch';

type IAppDate = {
  severity: IUpdateSeverity;
  date: string;
  version: string;
};

@UntilDestroy()
@Injectable()
export class CheckUpdatesService {
  private readonly window = inject(WINDOW_TOKEN);
  private readonly VERSION_IS_READY = 'VERSION_READY';
  private readonly updates = inject(SwUpdate);
  private readonly ngZone = inject(NgZone);
  private readonly sentry = inject(SENTRY_TOKEN);
  private readonly errorHandler = inject(ErrorHandler);

  private readonly ONE_HOUR_INTERVAL = dayjs().add(1, 'hour').diff(dayjs(), 'milliseconds');

  private readonly readyMessage = '[CheckUpdates]: Updates are ready to apply';
  private readonly availableMessage = '[CheckUpdates]: Updates available';
  private readonly reloadMessage = '[CheckUpdates]: Reload page to apply new version';
  private readonly unrecoverableMessage = '[CheckUpdates]: Unrecoverable error occurred';

  readonly updateIsReady$: Observable<IUpdateSeverity | null> = this.updates.versionUpdates.pipe(
    filter((event): event is VersionReadyEvent => event.type === this.VERSION_IS_READY),
    filter(state => state.latestVersion.hash !== state.currentVersion.hash),
    tap(() => this.sentry.track(this.readyMessage)),
    map(state => (state.latestVersion.appData as IAppDate)?.['severity'] ?? null)
  );

  constructor() {
    this.subscribeUnrecoverable();
  }

  private subscribeUnrecoverable(): void {
    this.updates.unrecoverable.pipe(untilDestroyed(this)).subscribe(event => {
      this.sentry.track(this.unrecoverableMessage, {reason: event.reason});
      this.unregisterServiceWorker();
    });
  }

  unregisterServiceWorker(): void {
    this.window.navigator.serviceWorker
      ?.getRegistrations()
      .then(async registrations => {
        for (const registration of registrations) {
          await registration.unregister();
        }
      })
      .then(() => this.window.location.reload());
  }

  initializeCheck(time?: number): Observable<boolean> {
    const interval$ = interval(time ?? this.ONE_HOUR_INTERVAL);

    return interval$.pipe(
      startWith(null),
      filter(() => this.window.navigator.onLine),
      switchMap(async () => {
        await this.window.navigator.serviceWorker?.ready;

        try {
          return await this.updates.checkForUpdate();
        } catch (err) {
          this.errorHandler.handleError(err);

          return false;
        }
      }),

      tap(hasUpdates => {
        if (hasUpdates) {
          this.sentry.track(this.availableMessage);
        }
      }),
      first(hasUpdates => hasUpdates)
    );
  }

  initializeReloadWhenHidden(): Observable<void> {
    const onTaskEmpty$ = merge(
      this.ngZone.onStable.pipe(map(() => true)),
      this.ngZone.onUnstable.pipe(map(() => false))
    );

    const isTabHidden$: Observable<boolean> = fromEvent(this.window, 'visibilitychange').pipe(
      map(() => this.window.document.hidden)
    );

    return this.updateIsReady$.pipe(
      take(1),
      switchMap(() => combineLatest([isTabHidden$, onTaskEmpty$])),
      filter((args: boolean[]) => args.every(item => item)),
      tap(() => {
        this.sentry.track(this.reloadMessage);
        this.window.location.reload();
      }),
      map(() => undefined)
    );
  }
}
